import { useRouter } from "next/router";

const useIsAuthPage = () => {
  const router = useRouter();
  const authPaths = [
    "/signin",
    "/signup",
    "/setpassword",
    "/forgotten",
    "/welcome",
    "/cancelled",
    "/onboarding",
    "/sign-up",
  ];
  const isAuthPage = authPaths.includes(router.pathname);

  return isAuthPage;
};

export default useIsAuthPage;
